.json-view {
    background: #F8FAFC;
    color: #B8BCCA;
    padding: 2em;
}

.json-view .indent {
    margin-left: 2em;
}

.json-view pre,
.json-view code {
    color: #B8BCCA;
    font-weight: 400;
    margin: 0;
    /* margin-bottom: 0.5em; */
    word-break: break-all;
    white-space: pre-wrap;
}

.json-view code.highlighted {
    font-weight: 700;
    color: #55575e;
}
