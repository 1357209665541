.request-notif-card {
  min-height: 100vh;
  width: 100%;
  background: #F6F8FF;
  padding: 100px 0;
  color: #838383;
  text-align: center;
}

.request-notif-card .heading,
.request-notif-card .sub-heading {
  color: #314D6CE5;
  font-weight: 500;
  margin-bottom: 20px;
}

.request-notif-card .descriptive-text {
  margin-top: 30px;
}
