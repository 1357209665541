.btn.star-nav-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  height: 60px;
  background: #F6F8FF;
  border-radius: 8px;
}

.star-nav-item .star-icon {
  display: grid;
  place-items: center;
  background: #3881F399;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
