.pool-registration {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2em;
}

.pool-registration h2 {
    text-align: left;
    font-weight: 600;
    font-size: 1.5em;
}

.privacy-policy {
    text-align: left;
}

.privacy-policy h1 {
    text-align: center;
}

.privacy-form {
    text-align: right;
    margin-bottom: 1em;
}

.privacy-form-buttons {
    margin-top: 3em;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}

.metadata-validation {
    text-align: left;
}
