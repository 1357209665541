table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    text-align: left;
}

table th {
    color: #314D6C;
    font-size: 1rem;
    font-weight: 500;
    padding: 0 10px;
}

thead tr {
    height: 60px;
    background: #F2F5FF;
}

tbody tr {
    height: 40px;
}

tbody tr:nth-child(even) {
    background: #F2F5FF;
}

tbody tr:nth-child(odd) {
    background: #F9FAFF;
}

table td {
    height: 50px;
    color: #838383;
    padding: 5px 10px;
    font-size: 0.8rem;
}

tr td:first-child,
tr th:first-child {
    padding-left: 20px;
}

tr td:last-child,
tr th:last-child {
    padding-right: 20px;
}

td.status .badge {
    width: 80px;
    padding: 5px 15px;
    border-radius: 4px;
    display: block;
    text-align: center;
}

td.status .badge.blue {
    background: #3881F326;
    color: #3881F3;
}

td.status .badge.red {
    background: #ed553b1A;
    color: #FB4545;
}

td.status .badge.orange {
    background: rgba(255, 138, 0, 0.13);
    color: #FD971E;
}
