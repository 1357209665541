.pool-block {
  overflow-x: scroll;
  -ms-overflow-style: none;
  text-align: left;
  padding: 20px;
}

.pool-block::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.statistics .pool-block .header .filter select {
  padding: 5px 30px 5px 10px;
  font-size: 1rem;
  height: 30px;
}

.pool-block .title .icon {
  margin-left: 10px;
  cursor: pointer;
}

.pool-block table th:last-child, .pool-block table th:first-child {
  width: 20%;
}

.pool-block table th {
  width: 10%;
}

.pool-block table td.data, td.comments {
  display: flex;
  align-items: center;
}

.pool-block table .data .icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

td.comments .text {
  margin-right: 10px;
}

.pool-block table td.input input {
  font-size: 0.6rem;
}

.pool-block .footer label {
  font-size: 1.2rem;
  margin-right: 40px;
  color: #314D6C;
}
.pool-block .footer select {
  border: none;
  outline: none;
  font-size: 1.2rem;
  margin-left: 10px;
}
.pool-block .footer {
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
}
.pool-block .footer .right {
  display: flex;
}
.pool-block .footer .right .btn {
  padding: 10px;
  border-radius: 5px;
  background: #3881F326;
  margin-left: 10px;
  cursor: pointer;
}