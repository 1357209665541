/* Box width */
.w-1-4 { width: 24.5%; }
.w-1-3 { width: 33%; }
.w-1-2 { width: 49%; }
.w-2-3 { width: 66%; }
.w-3-4 { width: 74%; }
.w-1-1 { width: 100%; }
.w-vw-1-4 { width: 24vw; }
.w-vw-1-3 { width: 33vw; }
.w-vw-1-2 { width: 49vw; }
.w-vw-2-3 { width: 66vw; }
.w-vw-3-4 { width: 74vw; }
.w-vw-1-1 { width: 100vw; }
/* Responsive */
@media (max-width: 768px) {
    .sm-w-vw-1-4 { width: 24.5%; }
    .sm-w-vw-1-3 { width: 33%; }
    .sm-w-vw-1-2 { width: 49%; }
    .sm-w-vw-2-3 { width: 66%; }
    .sm-w-vw-3-4 { width: 74%; }
    .sm-w-1-1 { width: 100%; }
    .sm-w-vw-1-4 { width: 25vw; }
    .sm-w-vw-1-3 { width: 33vw; }
    .sm-w-vw-1-2 { width: 49vw; }
    .sm-w-vw-2-3 { width: 66vw; }
    .sm-w-vw-3-4 { width: 74vw; }
    .sm-w-vw-1-1 { width: 100vw; }
}

/* Margins */
.m-0 { margin: 0!important; }
.mv-0 { margin-top: 0!important; margin-bottom: 0!important; }
.mh-0 { margin-left: 0!important; margin-right: 0!important; }
.mb-0 { margin-bottom: 0!important; }
.mt-0 { margin-top: 0!important; }
.ml-0 { margin-left: 0!important; }
.mr-0 { margin-right: 0!important; }

.m-10 { margin: 10px!important; }
.mb-10 { margin-bottom: 10px!important; }
.mt-10 { margin-top: 10px!important; }
.ml-10 { margin-left: 10px!important; }
.mr-10 { margin-right: 10px!important; }

.m-20 { margin: 20px!important; }
.mv-20 { margin-bottom: 20px!important; margin-top: 20px!important; }
.mb-20 { margin-bottom: 20px!important; }
.mt-20 { margin-top: 20px!important; }
.ml-20 { margin-left: 20px!important; }
.mr-20 { margin-right: 20px!important; }

.m-30 { margin: 30px!important; }
.mb-30 { margin-bottom: 30px!important; }
.mt-30 { margin-top: 30px!important; }
.ml-30 { margin-left: 30px!important; }
.mr-30 { margin-right: 30px!important; }

/* Padding */
.p-0 { padding: 0!important; }
.pv-0 { padding-top: 0!important; padding-bottom: 0!important; }
.ph-0 { padding-left: 0!important; padding-right: 0!important; }
.pb-0 { padding-bottom: 0!important; }
.pt-0 { padding-top: 0!important; }
.pl-0 { padding-left: 0!important; }
.pr-0 { padding-right: 0!important; }

.p-5 { padding: 5px!important; }
.pv-5 { padding-top: 5px!important; padding-bottom: 5px!important; }
.ph-5 { padding-left: 5px!important; padding-right: 5px!important; }
.pb-5 { padding-bottom: 5px!important; }
.pt-5 { padding-top: 5px!important; }
.pl-5 { padding-left: 5px!important; }
.pr-5 { padding-right: 5px!important; }

.p-10 { padding: 10px!important; }
.pv-10 { padding-top: 10px!important; padding-bottom: 10px!important; }
.ph-10 { padding-left: 10px!important; padding-right: 10px!important; }
.pb-10 { padding-bottom: 10px!important; }
.pt-10 { padding-top: 10px!important; }
.pl-10 { padding-left: 10px!important; }
.pr-10 { padding-right: 10px!important; }

.p-20 { padding: 20px!important; }
.pv-20 { padding-top: 20px!important; padding-bottom: 20px!important; }
.ph-20 { padding-left: 20px!important; padding-right: 20px!important; }
.pb-20 { padding-bottom: 20px!important; }
.pt-20 { padding-top: 20px!important; }
.pl-20 { padding-left: 20px!important; }
.pr-20 { padding-right: 20px!important; }

.p-30 { padding: 30px!important; }
.pv-30 { padding-top: 30px!important; padding-bottom: 30px!important; }
.ph-30 { padding-left: 30px!important; padding-right: 30px!important; }
.pb-30 { padding-bottom: 30px!important; }
.pt-30 { padding-top: 30px!important; }
.pl-30 { padding-left: 30px!important; }
.pr-30 { padding-right: 30px!important; }

/* Responsive */
@media (max-width: 768px) {
    /* Margins */
    .sm-m-0 { margin: 0!important; }
    .sm-mv-0 { margin-top: 0!important; margin-bottom: 0!important; }
    .sm-mh-0 { margin-left: 0!important; margin-right: 0!important; }
    .sm-mb-0 { margin-bottom: 0!important; }
    .sm-mt-0 { margin-top: 0!important; }
    .sm-ml-0 { margin-left: 0!important; }
    .sm-mr-0 { margin-right: 0!important; }

    .sm-mb-10 { margin-bottom: 10px!important; }
    .sm-mt-10 { margin-top: 10px!important; }
    .sm-ml-10 { margin-left: 10px!important; }
    .sm-mr-10 { margin-right: 10px!important; }

    .sm-mb-20 { margin-bottom: 20px!important; }
    .sm-mt-20 { margin-top: 20px!important; }
    .sm-ml-20 { margin-left: 20px!important; }
    .sm-mr-20 { margin-right: 20px!important; }

    .sm-mb-30 { margin-bottom: 30px!important; }
    .sm-mt-30 { margin-top: 30px!important; }
    .sm-ml-30 { margin-left: 30px!important; }
    .sm-mr-30 { margin-right: 30px!important; }

    /* Padding */
    .sm-p-0 { padding: 0!important; }
    .sm-pv-0 { padding-top: 0!important; padding-bottom: 0!important; }
    .sm-ph-0 { padding-left: 0!important; padding-right: 0!important; }
    .sm-pb-0 { padding-bottom: 0!important; }
    .sm-pt-0 { padding-top: 0!important; }
    .sm-pl-0 { padding-left: 0!important; }
    .sm-pr-0 { padding-right: 0!important; }

    .sm-p-10 { padding: 10px!important; }
    .sm-pv-10 { padding-top: 10px!important; padding-bottom: 10px!important; }
    .sm-ph-10 { padding-left: 10px!important; padding-right: 10px!important; }
    .sm-pb-10 { padding-bottom: 10px!important; }
    .sm-pt-10 { padding-top: 10px!important; }
    .sm-pl-10 { padding-left: 10px!important; }
    .sm-pr-10 { padding-right: 10px!important; }

    .sm-p-20 { padding: 20px!important; }
    .sm-pv-20 { padding-top: 20px!important; padding-bottom: 20px!important; }
    .sm-ph-20 { padding-left: 20px!important; padding-right: 20px!important; }
    .sm-pb-20 { padding-bottom: 20px!important; }
    .sm-pt-20 { padding-top: 20px!important; }
    .sm-pl-20 { padding-left: 20px!important; }
    .sm-pr-20 { padding-right: 20px!important; }

    .sm-p-30 { padding: 30px!important; }
    .sm-pv-30 { padding-top: 30px!important; padding-bottom: 30px!important; }
    .sm-ph-30 { padding-left: 30px!important; padding-right: 30px!important; }
    .sm-pb-30 { padding-bottom: 30px!important; }
    .sm-pt-30 { padding-top: 30px!important; }
    .sm-pl-30 { padding-left: 30px!important; }
    .sm-pr-30 { padding-right: 30px!important; }
}



/* Text manipulation */
.text-right { text-align: right; }
.text-left { text-align: left; }
.text-center { text-align: center; }
.underline { text-decoration: underline #314D6C; }

/* Div alignment manipulations */
.flex-right { display: flex; align-items: flex-end; }
.flex-left { display: flex; align-items: flex-start; }
.inline { display: inline-block; }

/* Visibility */
/* Responsive */
@media (max-width: 768px) {
    .sm-hidden {
        display: none!important;
    }
}
@media (min-width: 769px) {
    .lg-hidden {
        display: none!important;
    }
}


.row {
    display: flex;
    flex-direction: row;
}
.col { 
    display: flex; 
    flex-direction: column;
}
.wrap {
    flex-wrap: wrap;
}

.sp-between { justify-content: space-between; }
.sp-around { justify-content: space-around; }
.sp-evenly { justify-content: space-evenly; }
.items-end { align-items: flex-end; }
.items-start { align-items: flex-start; }
.items-center { align-items: center; }
.self-end { align-self: flex-end; }
.self-start { align-self: flex-start; }
.self-center { align-self: center; }

@media (max-width: 768px) {
    .sm-sp-between { justify-content: space-between; }
    .sm-sp-around { justify-content: space-around; }
    .sm-sp-evenly { justify-content: space-evenly; }
    .sm-items-end { align-items: flex-end; }
    .sm-items-start { align-items: flex-start; }
    .sm-items-center { align-items: center; }
    .sm-self-end { align-self: flex-end; }
    .sm-self-start { align-self: flex-start; }
    .sm-self-center { align-self: center; }
}

.red {
    color: red;
}
