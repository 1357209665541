.statistics {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  margin-bottom: 40px;
  position: relative;
}

.statistics .header .right {
  display: flex;
  align-items: center;
}

.statistics .btn {
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 1rem;
  height: 36px;
}

div.asc {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #314D6C;
  margin: 0 2px 2px 2px;
}

div.desc {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #314D6C;
  margin: 0 2px 2px 2px;
}

.statistics .btn {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #E1ECFD;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.statistics .export.btn svg {
  margin-left: 20px;
}

.statistics .pool-statistics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .statistics {
    padding: 0 10px;
  }
  .statistics .pool-statistics {
    flex-direction: column;
  }
}

.statistics .pool-block {
  margin-top: 30px;
}