.info-textbox {
    display: none;
    position: absolute;
    margin-left: 30px;
    z-index: 101;
    border: 1px solid darkgray;
    background-color: #F9FAFF;
    min-width: 100px;
    max-width: 300px;
    width: 200px;
    height: auto;
    padding: 3px 10px;
    overflow: scroll;
}

.info-textbox p {
    font-size: 1rem;
    font-weight: 100;
    overflow: scroll;
    overflow-wrap: normal;
}

.icon:hover + .info-textbox {
    display: block;
    border-radius: 5px;
}