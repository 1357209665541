.gc-sm {
  background-color: white;
  text-align: left;
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 10px;
  width: 49%;
}

.gc-sm hr {
  border-color: #eeeeee6c;
}

.gc-sm .bottom {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.gc-sm .bottom .icon {
  display: grid;
  place-items: center;
  background: #3881F31A;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.gc-sm .bottom .text {
  margin-left: 10px;
  color: #3881F3CC;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}