.radius-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .radius-card {
    border-radius: 10px!important;
  }
}

.radius-card hr {
  border-color: #eeeeee6c;
}

.radius-card .bottom {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.radius-card .bottom .icon {
  background: #3881F31A;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.radius-card .bottom .text {
  margin-left: 10px;
  color: #3881F3CC;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}
