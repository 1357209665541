.top-nav {
  display: flex;
  position: sticky;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-top: 10px;
  margin-left: 260px;
  top: 0;
  width: calc(100% - 260px);
  z-index: 100;
}

.top-nav > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.top-nav > *:last-child {
  align-items: center;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .top-nav {
    position: relative;
    justify-content: space-evenly;
    margin-left: 0;
    width: 100%;
  }
  .toggled-side-nav .top-nav {
    margin-left: 260px;
    margin-right: -260px;
  }
}

.top-nav .btn {
  background: #F6F8FF;
}
