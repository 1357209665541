.pools-table-view table th {
    width: 22%;
    /*&:nth-last-child(3), &:nth-last-child(2), &:last-child, &:first-child {*/
    /*    width: 8%;*/
    /*}*/
}

.pools-table-view table th:nth-last-child(3), .pools-table-view table th:nth-last-child(2), .pools-table-view table th:last-child, .pools-table-view table th:first-child {
    width: 8%;
}
