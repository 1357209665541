.side-nav {
  position: fixed;
  top: 0;
  width: 260px;
  text-align: left;
  background-color: white;
  overflow-y: scroll;
  z-index: 100;
  bottom: 0;
}

@media (max-width: 768px) {
  .side-nav {
    width: 0;
  }
  .toggled-side-nav > .side-nav {
    width: 260px;
  }
}

.side-nav::-webkit-scrollbar {
  width: 8px;
}
.side-nav::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}
.side-nav::-webkit-scrollbar-thumb {
  background-color: #76889d63;
  border-radius: 5px;
  display: none;
}
.side-nav:hover::-webkit-scrollbar-thumb {
  display: block;
}

.side-nav .logo {
  width: 150px;
  margin: 30px;
}

.nav-section {
  padding: 0px 20px;
  text-align: left;
}

.nav-section .section-title {
  color: rgba(49, 77, 108, 0.8);
  font-size: 1.2rem;
  margin: 20px 0;
}

.nav-section .nav-item, .nav-section .nav-category {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  font-size: 1rem;
  text-align: left;
  color: #838383;
  margin-bottom: 10px;
  cursor: pointer;
}
.nav-category .left {
  display: flex;
  justify-content: space-between;
}
.nav-item svg, .nav-category svg {
  width: 20px;
  margin: 0 10px 0 8px;
  fill: #1A395C;
  fill-opacity: 0.6;
}

.nav-section .nav-item.disabled {
  color: lightgray;
  cursor: default;
}
.nav-section .nav-item.disabled svg {
  fill: lightgray;
  cursor: default;
}

.nav-category {
  display: flex;
  justify-content: space-between;
}

.nav-item:not(.disabled):hover,
.nav-item:not(.disabled).active {
  color: #fff;
  background-color: dodgerblue;
}

.nav-item:not(.disabled):hover svg,
.nav-item:not(.disabled).active svg {
  fill: #fff;
  fill-opacity: 1;
}