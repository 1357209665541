.bar-container {
    width: 100%;
    align-self: flex-end;
}

.bar-container .bar {
    height: 10px;
    background: #FFA800;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
}
.bar-container .bar .progress {
    background-color: #3B60DE;
    border-right: 1px solid #fff;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    
    -webkit-animation: progress-bar 2s;
    -moz-animation: progress-bar 2s;
    animation: progress-bar 2s;
}

.bar-container .bar .limit {
    background: #FFA800;
    color: #FFA800;
}

.bar-container .bar .striped {
    background-image: linear-gradient(
            45deg,rgba(255,255,255,0.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,0.15) 50%,rgba(255,255,255,0.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
}

@-webkit-keyframes progress-bar {
    0% { width: 0; }
}
@-moz-keyframes progress-bar {
    0% { width: 0; }
}
@keyframes progress-bar {
    0% { width: 0; }
}
