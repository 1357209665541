.page-wrapper {
  position: relative;
  min-height:100vh;
}
.page-wrapper::after {
  content: '';
  display: block;
  height: 100px; /* Set same as page-footer's height */
}

.page-wrapper .page-container {
  display: flex;
  flex-direction: column;
  margin-left: 260px;
  background: #F6F8FF;
  overflow-y: auto;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge hide scrollbar */
  border-radius: 30px 0 0 30px;
  border-top: 10px solid #F6F8FF;
  width: calc(100% - 260px);
  padding: 10px 20px;
}

.page-wrapper .page-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.page-wrapper .toggle-side-nav-btn {
  display: none;

  outline: 0;
  top: 15px;
  margin-left: 10px;
  width: 35px;
  height: 50px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 102;
  position: absolute;
  background: lightgray no-repeat 45% 50%;
}

.page-wrapper .page-footer {
  position: absolute;
  bottom: 0;
  height: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 260px;
  width: calc(100% - 260px);
  padding: 10px 20px;
}

.page-wrapper .page-footer > div {
  padding: 10px;
}

.page-wrapper .page-footer > div > * {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .page-wrapper .page-container, .page-wrapper .page-footer {
    margin-left: 0;
    padding: 10px 5px;
    width: 100%;
  }
  .page-wrapper.toggled-side-nav .page-container, .page-wrapper.toggled-side-nav .page-footer {
    margin-left: 260px;
    margin-right: -260px;
  }
  .page-wrapper .toggle-side-nav-btn {
    display: block;
  }
  .page-wrapper.toggled-side-nav .toggle-side-nav-btn {
    margin-left: 270px;
  }
}
