.live-stats {
    justify-content: space-evenly;
    align-items: stretch;
    padding: 10px;
}

.live-stats > * {
    padding: 10px;
}

