.localized-loader-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 19, 26, 0.15);
  z-index: 10000;
  overflow: hidden;
}

.localized-loader,
.localized-loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.localized-loader {
  margin: 60px auto;
  font-size: 0.8rem;
  position: relative;
  text-indent: -9999em;
  border-top: 0.7em solid rgba(26, 137, 193, 0.2);
  border-right: 0.7em solid rgba(26, 137, 193, 0.2);
  border-bottom: 0.7em solid rgba(26, 137, 193, 0.2);
  border-left: 0.7em solid #1a89c1;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
