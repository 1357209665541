.managing .add-pool form {
    width: 100%;
}


.managing form .input {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
}
.managing form .input .icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.managing form .input input {
    width: 100%;
    font-size: 1rem;
    padding: 10px 10px 10px 0;
    outline: 0;
}

.managing form .submit-btn {
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #3881F3;
    width: 100%;
    color: white;
    cursor: pointer;
}