.no-auth-wrapper {
  display: flex;
  min-height: 100vh;
}

.no-auth-wrapper .left-side,.no-auth-wrapper .right-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-auth-wrapper .right-side .logo {
  display: none;
  align-self: center;
  flex-direction: row;
  width: 50%;
}

.no-auth-wrapper .right-side > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.no-auth-wrapper .right-side {
  background-color: #FFFFFF;
  padding: 40px;
}

.no-auth-wrapper .left-side {
  /* background-color: #3881F3; */
  background-position: center;
  background-size: cover;
}

.no-auth-wrapper .left-side .filter {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background: rgba(61, 93, 222, 0.301);
}

.no-auth-wrapper .left-side .content-card {
  width: 65%;
  max-width: 650px;
  height: 80%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.219);
  /* background-color: rgb(195 195 195 / 22%); */
}

.no-auth-wrapper .logo-card {
  max-width: 65%;
  padding: 3vmin 4vmin;
  margin-top: 0;
}
.no-auth-wrapper img {
  width: 100%;
}

.no-auth-wrapper .content-card .bottom-text {
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  margin: 20px;
  color: #FFFFFF;
}

.no-auth-wrapper .right-side .footer {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.no-auth-wrapper .footer > div {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: space-around;
  padding: 10px 0;
}

.no-auth-wrapper .footer > div > div {
  padding: 5px;
}

@media (max-width: 768px) {
  .no-auth-wrapper .left-side {
    display: none;
  }
  .no-auth-wrapper .right-side {
    width: 100%;
    padding: 30px 10px;
    justify-content: space-between;
  }
  .no-auth-wrapper .right-side .logo {
    display: flex;
  }
  .no-auth-wrapper .footer {
    padding: 10px 5px;
  }
}
