.profile {
    margin-bottom: 40px;
    position: relative;
}

.profile .photo {
    margin-right: 20px;
}

.profile .photo img {
    border-radius: 8px;
    width: 170px;
    border: 4px solid #F6F8FF;
}

.profile .photo .input {
    height: 30px; 
    width: 190px;
}

.profile .photo .input input {
    width: 150px;
}

.profile .userinfo > div {
    height: 30px;
    width: 100%;
}
