.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.no-data .title {
    font-size: 2em;
    font-weight: 700;
}