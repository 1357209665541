html {
  font-size: 11px;
}

@media (max-width: 768px) {
  html {
    font-size: 9px;
  }
}

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
  color: #314D6C;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.title {
  color: #314D6C;
  display: flex;
  align-items: center;
}
.title .icon {
  margin-left: 10px;
  cursor: pointer;
}
span.icon {
  display: grid;
  place-items: center;
}

/* Header + header filter */
.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
  margin-top: 0;
  padding: 10px 15px;
}

.header > * {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  align-self: center;
}

.header .title {
  margin: 10px 10px 10px 0;
  
  font-size: 1.5rem;
  color: #314D6C;
  font-weight: bold;
}

.header .title.sub {
  margin-top: 5px;
  margin-bottom: 0;
  
  font-size: 1.2rem;
}

.header .filter * {
  font-size: 1rem;
}

.header .filter > * {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  margin-right: 10px;
  margin-bottom: 5px;
}

.header .filter .title {
  align-items: flex-start;
  
  padding: 0 10px 0 0;
  margin: 5px 0;
  
  font-weight: 500;
}

@media (max-width: 480px) {
  .header {
    padding: 5px;
  }
}

/***************************/

.btn {
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin: 0 5px 0 0;
  font-size: 1rem;
  height: 36px;
  display: flex;
  align-items: center;
  background: #3881F3;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .btn {
    padding: 6px;
    border-radius: 4px;
    height: 24px;
  }
}

.btn.secondary {
  background: #E1ECFD;
  color: #3881F3;
}

.btn.disabled, button:not(.link).disabled {
  background: #8098C1;
}

button.yes, button.no {
  padding: 0;
  margin: 5px 5px 1px;
  font-size: 0.8rem;
  border: none;
  background-color: transparent;
}

button.yes.loading:before, button.no.loading:before,
button.yes.loading:hover:before, button.no.loading:hover:before{
  border: 0.3px solid lightgray;
  color: lightgray;
}

button.yes:before {
  padding: 0 3px;
  content: "\2713";
  border: 0.3px solid #1C791C88;
  border-radius: 2px;
  color: #1C791C88;
}

button.no:before {
  padding: 0 4px;
  content: "\2717";
  border: 0.3px solid #8B000088;
  border-radius: 2px;
  color: #8B000088;
}

button.yes:hover:before {
  border-color: #1C791CFF;
  color: #1C791CFF;
}

button.no:hover:before {
  border-color: #8B0000FF;
  color: #8B0000FF;
}

button.link:hover {
  color: #255EBA;
  text-decoration: underline;
}

.link.simple-link {
  color: #3881F3;
  text-decoration: none;
}

.link.simple-link:hover {
  color: #255EBA;
  text-decoration: underline;
}

button.link {
  cursor: pointer;
  font-size: 1em;
  border: none;
  color: #3881F3;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  background: none;
}

input {
  font-size: 0.8rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #BCBCBC
}

textarea {
  font-size: 0.8rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #BCBCBC
}

input[type="file"] {
  border-bottom: none;
}

select {
  padding: 10px 40px 10px 10px;
  margin-right: 10px;
  height: 36px;

  font-size: 1rem;
  
  border-radius: 5px;
  color: #3881F3;
  outline: 0;
  border: none;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* background-image: url(img/down-arrow.png); */
  background-size: 16px;
  background: #E1ECFD no-repeat 90% 50%;
}

label {
  margin-right: 10px;
}

a {
  color: #3881F3;
}

a:hover {
  color: #255EBA;
}

.inline .btn {
  font-size: 1em;
  height: 24px;
}

.error {
  color: red;
}

.notification {
  text-align: left;
  
}

.notification.neutral {
  color: darkorange;
}

.small {
  font-size: 0.8rem;
}

.horizontal-scroll {
  overflow-x: scroll;
}
.horizontal-scroll.bar-hide::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.scroll-rigth {
  direction: rtl;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #76889d63;
  border-radius: 5px;
  display: none;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  display: block;
}