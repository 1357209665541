.large-button {
    border-radius: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75em 1.25em;
    gap: 1.5em;
    background-color: #3781f3;
    border: none;
    cursor: pointer;
    margin-bottom: 2em;
}

.large-button.disabled {
    background: #ACB5C3 !important;
    cursor: auto;
}

.large-button-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.25em;
}

.large-button-title,
.large-button-subtitle {
    text-align: left;
    font-size: 1.5em;
    color: #fff;
    padding: 0;
    margin: 0;
}

.large-button-subtitle {
    font-size: 1em;
}