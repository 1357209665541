.contact-us-form {
  color: #838383;
}

.contact-us-form .heading,
.contact-us-form .sub-heading {
  color: #314D6CE5;
  font-weight: 500;
  text-align: left;
  margin: 0 0 20px;
}

.text-blue {
  color: #3881F3;
}

.contact-us-form .descriptive-text {
  color: #3881F3;
  font-weight: 600;
  text-align: left;
  margin-top: 40px;
}

.contact-us-form .input {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
}
.contact-us-form .input .icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.contact-us-form .input input {
  width: 100%;
  font-size: 1rem;
  padding: 10px 10px 10px 0;
  outline: 0;
}

.contact-us-form input[type="checkbox"] {
  margin-left: 10px;
  margin-right: 10px;
  width: 11px;
  height: 11px;
}

.contact-us-form .submit-btn {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #3881F3;
  width: 100%;
  color: white;
  cursor: pointer;
}

.contact-us-form a {
  text-decoration: none;
}

.contact-us-form .or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  color: #000;
  font-weight: bold;
}

.contact-us-form .or::before,
.or::after {
  content: "";
  width: 50px;
  border-bottom: 1px solid #BCBCBC;
}

.or::before {
  margin-right: 10px;
}

.or::after {
  margin-left: 10px;
}

.social-login-btns {
  display: flex;
  justify-content: center;
}

.social-login-btn {
  padding: 10px 30px;
  width: 120px;
  margin: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.social-login-btn img {
  width: 20px;
}

.facebook {
  border: 1px solid #3B5998;
}

.google {
  border: 1px solid #CED9DF;
}

.google:hover {
  background: #E1ECFD;
}

.apple {
  border: 1px solid #535353;
}