.login-form {
  width: 80%;
  /* margin: 40px 0; */
  background: #F6F8FF;
  padding: 40px;
  color: #838383;
}

@media (max-width: 768px) {
  .login-form {
    padding: 20px;
  }
}

.login-form .heading,
.login-form .sub-heading {
  color: #314D6CE5;
  font-weight: 500;
  text-align: left;
  margin: 0 0 20px;
}

.text-blue {
  color: #3881F3;
}

.login-form .descriptive-text {
  color: #3881F3;
  font-weight: 600;
  text-align: left;
  margin-top: 40px;
}

.login-form .input {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
}
.login-form .input .icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.login-form .input input {
  width: 100%;
  font-size: 1rem;
  padding: 10px;
  outline: 0;
}

.text-right {
  margin-top: 0;
  text-align: right;
}

.login-form .forget-pass {
  color: #3881F3;
  text-decoration: none;
}

.login-form .signin-btn {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #3881F3;
  width: 100%;
  color: white;
  cursor: pointer;
}

.login-form a {
  text-decoration: none;
}

.login-form .or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  color: #000;
  font-weight: bold;
}

.login-form .or::before,
.or::after {
  content: "";
  width: 50px;
  border-bottom: 1px solid #BCBCBC;
}

.or::before {
  margin-right: 10px;
}

.or::after {
  margin-left: 10px;
}

.social-login-btns {
  display: flex;
  justify-content: center;
}

.social-login-btn {
  padding: 10px 30px;
  width: 120px;
  margin: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.social-login-btn img {
  width: 20px;
}

.facebook {
  border: 1px solid #3B5998;
}

.google {
  border: 1px solid #CED9DF;
}

.google:hover {
  background: #E1ECFD;
}

.apple {
  border: 1px solid #535353;
}